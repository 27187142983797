
.add-visit-profile-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* padding: 20px; */
}

.add-content-wrapper {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15px;
    min-height: 300px;
    box-sizing: border-box;
}
.custom-button-style {
    width: 112px;
    height: 28px;
    padding: 4px 12px;
    gap: var(--2);
    border-radius: 6px 0px 0px 0px;
    border: 1px solid var(--MIsc-Stroke, #DFE4EA);
    opacity: 0;
}

.add-visit-profile-row-container {
    max-height: 370px;
    overflow-y: auto;
    padding-right: 10px;
    margin: 20px 0;
}
.add-visit-profile-rounded-fields-container {
    background-color: white;
    width: 90%;
    padding: 16px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid white; /* Change border color to white */
    box-shadow: 0px 1px 3px 0px #3232471A, 0px 0px 1px 0px #0C1A4B33; /* Apply box-shadow */
}

.maindiv{
    display: flex;
    align-items: center;
}
.add-visit-profile-error-text {
    color: red;
    font-size: 12px;
}

.add-visit-profile-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 0;
}
.add-visit-profile-form-actions-left {
    display: flex;
    justify-content: flex-start;  
    gap: 10px;
    padding: 10px 0;
  }
.removeIcon{
    width: 10%;
    display: flex;
   padding: 25px;
}
.VisitProfileform{
    margin-left: 6%;
    margin-right: 6%;
}
/* VisitlocationsRow.css */

.visit-profile-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.content-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
    width: 400px;
    height: 260px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    opacity: 1;
    z-index: 9999;
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
}
.modal-content {
    text-align: center;
    padding: 16px;
}
.modal-img{
    display: flex;
    justify-content: center;
}
.modal-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
   
}

.modal-buttons {
    display: flex;
    justify-content: center;
   gap: 12px;
   margin-top: 20px;
}
