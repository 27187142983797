@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('../../assets/appleFont/SFProDisplay-Regular.ttf') format('truetype');
}

.textarea-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 452px;
}

.textarea-main {
    background-color: #F9FAFB;
    border-radius: 4px 0px 0px 0px;
    padding: 5px 10px;
    border: 1px solid transparent;
    width: 100%;
    height: 124px;
}

.textarea-field {
    background-color: transparent;
    font-family: "SFProDisplayRegular";
    color: #637381;
    font-size: 14px;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
}

.textarea-field:focus {
    outline: none;
    border: none;
    }

.textarea-disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #DEE2E6;
}

.error-text-container {
    background-color: #FFE3E3;
    padding: 0px 8px;
    font-family: "SFProDisplayRegular";
    font-size: 14px;
    color: #C80000;
    height: 22px;
    border-radius: 4px;
    gap: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.error-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    max-width: calc(100% - 24px);
}
.textarea-field::placeholder {
    transition: none;
    opacity: 1;
}

.textarea-field:focus::placeholder {
    opacity: 0;
    transition: none;
}
