@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('../../assets/appleFont/SFProDisplay-Regular.ttf') format('truetype');
}


.custom-single-select-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.custom-single-select-div {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.custom-single-select {
    padding: 4px 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9FAFB;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #637381;
    font-family: "SFProDisplayRegular";
    cursor: pointer;
    height: 32px;
}

.main-label-container {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-single-select-dropmenu-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #DFE4EA;
    background-color: #F9FAFB;
    padding: 8px;
    gap: 8px;
    top: 80%;
    width: 100%;
    z-index: 1;
}

.custom-single-select-options {
    border-radius: 4px;
    background-color: #F9FAFB;
    max-height: 150px;
    overflow-y: auto;
}

.custom-single-select-options::-webkit-scrollbar {
    width: 10px;
}

/* background of the scrollbar except button or resizer */
.custom-single-select-options::-webkit-scrollbar-track {
    background-color: #fff;
}

.custom-single-select-options::-webkit-scrollbar-button {
    display: none;
}

/* scrollbar itself */
.custom-single-select-options::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: 2px solid #fff;
    min-height: 40px;
}

.custom-single-select-search-container {
    border-radius: 48px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    display: flex;
}

.search-input {
    width: 100%;
    border-radius: 48px;
    outline: none;
    color: #637381;
    font-size: 14px;
    line-height: 22px;
    font-family: "SFProDisplayRegular";
}

.custom-single-select-option {
    padding: 4px 10px;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    font-family: "SFProDisplayRegular";
    color: #1E293B;
    background-color: #FFFFFF;
    height: 32px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.primary-label-div {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    max-width: 70%;
}

.custom-radio-input {
    width: 16px;
    height: 16px;
    border: 1px solid #BDBDBD;
    border-radius: 50%;
    background-color: #FFFFFF;
    appearance: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.custom-radio-input:checked {
    border-color: #006CFA;
}

.custom-radio-input:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #006CFA;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
}

.secondary-label {
    font-family: "SFProDisplayRegular";
    font-size: 12px;
    line-height: 20px;
    color: #637381;
}

.custom-single-select-option:hover {
    background-color: #F3F4F6;
}

.custom-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.custom-single-select-icon {
    width: 16px;
    height: 16px;
    align-items: center;
    display: flex;
}

.label-text {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error-single-select-container {
    background-color: #FFE3E3;
    padding: 0px 8px;
    font-family: "SFProDisplayRegular";
    font-size: 14px;
    color: #C80000;
    height: 22px;
    border-radius: 4px;
    gap: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.single-select-error-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    max-width: calc(100% - 24px);
}

.single-select-main {
    display: flex;
    gap: 4px;
    height: 82px;
}
.custom-single-select-label-container {
    display: flex;
    gap: 8px;
    align-items: center;
}