@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('../../assets/appleFont/SFProDisplay-Regular.ttf') format('truetype');
}

.custom-subordinate-select-container {
    position: relative;
    width: 160px;
    cursor: pointer;
    height: 30px;
}

.custom-subordinate-select {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DFE4EA;
    background-color: #F3F4F6;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #384153;
    font-family: "SFProDisplayRegular";
}

.main-label-container {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.single-select-main {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.custom-subordinate-select-options {
    top: 120%;
    right: 0;
    left: auto;
    width: 150%;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    background-color: #FFFFFF;
    z-index: 10;
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
}

.custom-subordinate-select-option {
    padding: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "SFProDisplayRegular";
    color: #384153;
}

.custom-subordinate-select-option:hover {
    background-color: #f0f0f0;
}

.custom-disabled {
    opacity: 0.5;
    pointer-events: none;
}